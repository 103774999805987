import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { GlobalStyle } from 'pretty-decent-ui'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/Footer"
import theme from "../theme-posts"

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto 100px;
  line-height: inherit;
  min-height: 85vh;
  color: ${props => props.theme.colors.gray[900]};
`

const NotFoundPage = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Container className="pt-12 pb-4">
      <Layout>
        <SEO title="404: Not found" />
        <h1 className="pt-20">NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Layout>
    </Container>
    <Footer />
  </ThemeProvider>
)

export default NotFoundPage
